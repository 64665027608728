exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("./../../../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-pt-br-js": () => import("./../../../src/pages/404.pt-br.js" /* webpackChunkName: "component---src-pages-404-pt-br-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contacto-es-js": () => import("./../../../src/pages/contacto.es.js" /* webpackChunkName: "component---src-pages-contacto-es-js" */),
  "component---src-pages-contato-pt-br-js": () => import("./../../../src/pages/contato.pt-br.js" /* webpackChunkName: "component---src-pages-contato-pt-br-js" */),
  "component---src-pages-elektroteks-acquires-mammut-en-js": () => import("./../../../src/pages/elektroteks-acquires-mammut.en.js" /* webpackChunkName: "component---src-pages-elektroteks-acquires-mammut-en-js" */),
  "component---src-pages-elektroteks-acquires-mammut-es-js": () => import("./../../../src/pages/elektroteks-acquires-mammut.es.js" /* webpackChunkName: "component---src-pages-elektroteks-acquires-mammut-es-js" */),
  "component---src-pages-elektroteks-acquires-mammut-pt-br-js": () => import("./../../../src/pages/elektroteks-acquires-mammut.pt-br.js" /* webpackChunkName: "component---src-pages-elektroteks-acquires-mammut-pt-br-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-br-js": () => import("./../../../src/pages/index.pt-br.js" /* webpackChunkName: "component---src-pages-index-pt-br-js" */),
  "component---src-pages-pecas-de-reposicao-pt-br-js": () => import("./../../../src/pages/pecas-de-reposicao.pt-br.js" /* webpackChunkName: "component---src-pages-pecas-de-reposicao-pt-br-js" */),
  "component---src-pages-politica-de-privacidad-es-js": () => import("./../../../src/pages/politica-de-privacidad.es.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-es-js" */),
  "component---src-pages-politica-de-privacidade-pt-br-js": () => import("./../../../src/pages/politica-de-privacidade.pt-br.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-pt-br-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-repuestos-es-js": () => import("./../../../src/pages/repuestos.es.js" /* webpackChunkName: "component---src-pages-repuestos-es-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-servicios-es-js": () => import("./../../../src/pages/servicios.es.js" /* webpackChunkName: "component---src-pages-servicios-es-js" */),
  "component---src-pages-servicos-pt-br-js": () => import("./../../../src/pages/servicos.pt-br.js" /* webpackChunkName: "component---src-pages-servicos-pt-br-js" */),
  "component---src-pages-sobre-nos-pt-br-js": () => import("./../../../src/pages/sobre-nós.pt-br.js" /* webpackChunkName: "component---src-pages-sobre-nos-pt-br-js" */),
  "component---src-pages-sobre-nosotros-es-js": () => import("./../../../src/pages/sobre-nosotros.es.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-es-js" */),
  "component---src-pages-spare-parts-en-js": () => import("./../../../src/pages/spare-parts.en.js" /* webpackChunkName: "component---src-pages-spare-parts-en-js" */),
  "component---src-templates-machine-en-js": () => import("./../../../src/templates/machine.en.js" /* webpackChunkName: "component---src-templates-machine-en-js" */),
  "component---src-templates-machine-es-js": () => import("./../../../src/templates/machine.es.js" /* webpackChunkName: "component---src-templates-machine-es-js" */),
  "component---src-templates-machine-pt-br-js": () => import("./../../../src/templates/machine.pt-br.js" /* webpackChunkName: "component---src-templates-machine-pt-br-js" */),
  "component---src-templates-manufacturer-en-js": () => import("./../../../src/templates/manufacturer.en.js" /* webpackChunkName: "component---src-templates-manufacturer-en-js" */),
  "component---src-templates-manufacturer-es-js": () => import("./../../../src/templates/manufacturer.es.js" /* webpackChunkName: "component---src-templates-manufacturer-es-js" */),
  "component---src-templates-manufacturer-pt-br-js": () => import("./../../../src/templates/manufacturer.pt-br.js" /* webpackChunkName: "component---src-templates-manufacturer-pt-br-js" */)
}

